<template>
    <div>
        <br />
        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" />
        <br /><br />
        <div class="login md-alignment-top-center md-layout">
            <md-card md-with-hover class="md-layout-item md-size-30">
                
                <form @submit.prevent="submit">

                <md-card-content>
                    <md-vuelidated>
                        <label>Email</label>
                        <md-input v-model.trim="form.email" type="email"></md-input>
                        <md-vuelidated-msg constraint="required">Merci de saisir l'email de votre compte</md-vuelidated-msg>
                        <md-vuelidated-msg constraint="email">L'email que vous avez saisi n'est pas valide</md-vuelidated-msg>
                        <span class="md-error"></span>
                    </md-vuelidated>
                    <md-vuelidated>
                        <label>Mot de passe</label>
                        <md-input v-model.trim="form.password" type="password"></md-input>
                        <md-vuelidated-msg constraint="required">Merci de saisir votre mot de passe</md-vuelidated-msg>
                    </md-vuelidated>
                </md-card-content>

                <md-card-actions>
                <md-button class="md-raised md-accent" @click="$router.push('/lost');">Oublié mon mot de passe</md-button>
                <md-button class="md-raised md-green" type="submit">Connexion</md-button>
                </md-card-actions>
                </form>
            
            </md-card>
        </div>
    </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'


export default {
  name: 'Login',
  data(){
      return {
          loader: null,
          form:{
              email:'',
              password:''
          }
      }
  },
  validations:{
      form:{
          email:{
              required,
              email
          },
          password:{
              required
          }
      }
  },
  methods:{
      submit(){
          this.$v.$touch();
          if(!this.$v.$invalid){
              // submission
              this.loader=this.$loading.show();
              this.$store.dispatch('user/login', this.form)
              .then(() => {
                  this.loader.hide();
                  console.log('redirection to /stock');
                  this.$router.push({path: '/stock'})
              }).catch(() => {
                  this.loader.hide();
                  this.$alert("L'authentification n'a pas fonctionnée. Veuillez vérifier vos identifiants",'Error','error');
              })
          }
      }
  }
  
}
</script>
<style lang="scss" scoped>
.md-layout-item{
    flex-basis: 400px;
    min-width: 300px;
    max-width: 400px;
}
</style>